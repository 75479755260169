import React, { useEffect, useRef } from 'react';

const YouTubeVideo = ({ id, index }) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const aspectRatio = 16 / 9; // Adjust this based on your video's aspect ratio
      const containerWidth = iframeRef.current.parentElement.offsetWidth;
      const screenWidth = window.innerWidth;
      const mult = screenWidth < 700 ? 1 : 0.9;
      const calculatedHeight = mult*(containerWidth / aspectRatio);
      iframeRef.current.style.height = `${calculatedHeight}px`;
      const header = document.querySelector('header');
      var offset = header.offsetHeight;
      const rightArrow = document.querySelector('.custom-prev-arrow');
      rightArrow.style.top = `${calculatedHeight/2 + offset}px`;
      const leftArrow = document.querySelector('.custom-next-arrow');
      leftArrow.style.top = `${calculatedHeight/2 + offset}px`;
    };

    // Attach the event listener to adjust the height on window resize
    window.addEventListener('resize', handleResize);

    // Call handleResize once to set initial height
    handleResize();

    // Cleanup: Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const screenWidth = window.innerWidth;
  const width = screenWidth < 700 ? '100%' : '90%';

  return (
    <div className="youtube-video-container" key={index}>
      <iframe
        ref={iframeRef}
        id={`youtube-video-${index}`}
        title={`YouTube Video ${index + 1}`}
        width={width}
        height="auto" // The height will be adjusted dynamically
        src={`https://www.youtube.com/embed/${id}`}
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default YouTubeVideo;
