import React, { useEffect, useState } from 'react';
import './About.css';
import { fetchEntries } from '../CMSProcessing';

const App = () => {
  const [aboutData, setAboutData] = useState({
    aboutPara: 'Loading...',
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const entries = await fetchEntries();
        entries.forEach(function (entry) {
          if (entry.fields.about) {
            setAboutData({
              aboutPara: entry.fields.about,
            });
          }
        });
      } catch (error) {
        console.error('Error fetching entries:', error);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="about">
      {/* Add header content here */}
      <div className="about-text" id="about-text">
        {aboutData.aboutPara}
      </div>
      <div className="line"></div>
    </div>
  );
};

export default App;
