import React, { useEffect, useState } from 'react';
import { fetchEntries } from '../CMSProcessing';

import './Sponsors.css';

const App = () => {
    const [data, setData] = useState({
        Sponsors: []
    });

    useEffect(() => {

        async function fetchData() {
            try {

                const entries = await fetchEntries();
                const Sponsors = [];


                for (const entry of entries) {
                    if (entry.fields.sponsorLogo) {
                        Sponsors.push(
                            "https:" + entry.fields.sponsorLogo.fields.file.url
                        )
                    }
                }



                setData({
                    Sponsors: Sponsors,
                })

            } catch (error) {
                console.error('Error fetching entries:', error);
            }
        }

        fetchData();

    }, []);


    return (
          <div class='sponsor-background'>
            <h2>Our Sponsors</h2>
            <div class='sponsor-pics'>
            {data.Sponsors.map((item, index) => 
          (
            <img src={item} class='sponsor-pic' alt='sponsor pic' />
          ))}
            </div>
          
          </div>
    );
    

}


export default App;