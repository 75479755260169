// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/Home';
import NewsPage from './components/News/News';
import TheTeam from './components/MeetTheTeam/TheTeam';
import FindUs from './components/FindUs/FindUs';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/news" element={<NewsPage/>} />
        <Route path="/meet-the-team" element={<TheTeam/>} />
        <Route path="/find-us" element={<FindUs/>} />
      </Routes>
    </Router>
  );
};

export default App;
