import React, { useRef, useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import YouTubeVideo from './YoutubeVideo'; // Import the modified YouTubeVideo component
import { fetchEntries } from '../CMSProcessing';
import './VideoSection.css';

const YouTubeVideoCarousel = () => {
  const [data, setData] = useState({
    urls: [],
  });

  const [activeIndex, setActiveIndex] = useState(0);
  const sliderRef = useRef(null);

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoPlay: false,
    autoplay: false,
    pauseOnHover: true,
    dots: true,
    arrows: false,
    beforeChange: (oldIndex, newIndex) => {
      sliderRef.current.slickPause();
    },
    afterChange: (currentIndex) => {
      setActiveIndex(currentIndex);
    },
    customPaging: (i) => (
      <div className={`custom-dot${i === activeIndex ? ' active' : ''}`}></div>
    ),
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const entries = await fetchEntries();
        const trimmedVideoIds = [];

        for (let i = 0; i < entries.length; i++) {
          var entry = entries[i];
          if (entry.fields.highlightUrl) {
            const url = entry.fields.highlightUrl;
            trimmedVideoIds.push(url.slice(32));
            }

        }

        setData({
          urls: trimmedVideoIds,
        });
      } catch (error) {
        console.error('Error fetching entries:', error);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="youtube-video-carousel-container">
    <Slider ref={sliderRef} {...settings} style={{ width: '100%', margin: '0 auto' }}>
      {data.urls.map((id, index) => (
        <YouTubeVideo key={index} id={id} index={index} />
      ))}
    </Slider>
    {/* Custom navigation arrows */}
    <CustomPrevArrow onClick={() => sliderRef.current.slickPrev()} />
      <CustomNextArrow onClick={() => sliderRef.current.slickNext()} />
    </div>
  );
};


const CustomPrevArrow = ({ onClick }) => (
  <div className="custom-prev-arrow" onClick={onClick}>
    {/* Your custom previous arrow content */}
    <i class="fa-solid fa-arrow-left fa-2xl"></i>
  </div>
);

const CustomNextArrow = ({ onClick }) => (
  <div className="custom-next-arrow" onClick={onClick}>
    {/* Your custom next arrow content */}
    <i class="fa-solid fa-arrow-right fa-2xl"></i>
  </div>
);

export default YouTubeVideoCarousel;
