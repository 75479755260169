import React, { useEffect, useState } from 'react';
import { fetchEntries } from '../CMSProcessing';
import './LatestNews.css';

const App = ({ news }) => {
  const [data, setData] = useState({
    articles: [],
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const entries = await fetchEntries();
        const articles = [];

        for (let i = 0; i < entries.length; i++) {
          var entry = entries[i];
          if (entry.fields.article) {
            var title = entry.fields.articleTitle;
            var body = [];
            for (const bod of entry.fields.article.content) {
              var nodeType = bod.nodeType;
              if (nodeType === "paragraph") {
                body.push("<p>" + bod.content[0].value + "</p>");
              } else if (nodeType === "embedded-asset-block") {
                body.push("<img alt='content' src='https:" + bod.data.target.fields.file.url + "'>");
              }
            }
            const results = [];
            if (entry.fields.results){
              for (const result of entry.fields.results) {
                results.push("https:" + result.fields.file.url)
              }
            }
            

            articles.push({ title: title, body: body, results: results });
          }
        }
        
        setData({
          articles: articles,
        })

      } catch (error) {
        console.error('Error fetching entries:', error);
      }
    }

    fetchData();
  }, []);

  const toggleResults = (index, event) => {
    event.preventDefault(); // Prevent the default behavior of the anchor

    const results = document.querySelector(`.post-results-${index}`);
    const article = document.querySelector(`.post-article-${index}`);
    const anchor = document.querySelector(`.toggle-results-${index}`);
    if (data.articles[index].results.length !== 0){
      results.classList.toggle('active');
    }
      

    article.classList.toggle('active');
    if (anchor.innerHTML === 'Read Less'){
      anchor.innerHTML = 'Read More';
    } else {
      anchor.innerHTML = 'Read Less';
    }
    
  };
  return (
    <div className="latest-news">
      {data.articles.slice(0, parseFloat(news)).map((article, index) => (
        <div className={`post post-${index}`} key={index}>
          <div className={`post-article post-article-${index}`}>
            <div class='post-top'>
            <h2>{article.title}</h2>
            {/* eslint-disable-next-line */}
            <a href='#' className={`toggle-results toggle-results-${index}`} onClick={(event) => toggleResults(index, event)}>
                  Read more
            </a>
            </div>
            <div dangerouslySetInnerHTML={{ __html: article.body.join('') }} />
          </div>
          <div className={`post-results post-results-${index}`}>
            {article.results.map((result, resultIndex) => (
              <img key={resultIndex} alt='content' src={result} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default App;
