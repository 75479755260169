// contentfulService.js
const contentful = require('contentful');

const client = contentful.createClient({
  space: 'kjtqbxkmmiyi',
  environment: 'master',
  accessToken: 'sADf4FEpdBqtd2is1hevuVJXZcgczqB50LjBhXyC08A',
});

async function fetchEntries() {
  try {
    const entries = await client.getEntries();
    return entries.items;
    
  } catch (error) {
    console.error('Error fetching entries:', error);
    throw error; // Handle the error appropriately in your application
  }
}

module.exports = { fetchEntries };