import React from 'react';
import Header from '../Header/Header';
import LatestNews from '../LatestNews/LatestNews';
import Footer from '../Footer/Footer';
import Sponsors from '../Sponsors/Sponsors';

import './News.css'

const App = () => {
  return (
    <div class='all'>
      <Header />
      <LatestNews news="Infinity" results = "Infinity" />
      <Sponsors />
      <Footer />
    </div>
  );
};

export default App;
