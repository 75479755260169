import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import './TrainingVideos.css'
import { fetchEntries } from '../CMSProcessing';


const YoutubeEmbed = () => {
  const [data, setData] = useState({
    urls: [],
  });

  useEffect(() => {

  async function fetchData() {
    try {
      const entries = await fetchEntries();
      const trimmedVideoIds =[];

      for (let i = 0; i < entries.length; i++) {
        var entry = entries[i];
        if (entry.fields.trainingUrl) {
          const url = entry.fields.trainingUrl;
          trimmedVideoIds.push(url.slice(32));
        

          

          
        }
      }
      setData({
        urls: trimmedVideoIds,
      })
      

    } catch (error) {
      console.error('Error fetching entries:', error);
    }
  }

  fetchData();
}, []);

  return (
  <div>
  <div className="video-responsive">
    {data.urls.map((id, index) => (
    <iframe
      width="534px"
      height="300px"
      src={'https://www.youtube.com/embed/'+id}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
    ))}
  </div>
  <div class="news-line">
  </div>
  </div>
);}

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;