import React from 'react';
import Header from './Header/Header';
import VideoSection from './VideoSection/VideoSection';
import About from './About/About';
import TrainingVideos from './TrainingVideos/TrainingVideos';
import LatestNews from './LatestNews/LatestNews';
import Sponsors from './Sponsors/Sponsors';
import Footer from './Footer/Footer';

const App = () => {
  return (
    <div class='all'>
      <Header />
      <VideoSection />
      <About />
      <TrainingVideos />
      <LatestNews news="1"/>
      <Sponsors />
      <Footer />
    </div>
  );
};

export default App;
