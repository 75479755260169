import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

import logoImage from '../../assets/nba-logo.jpg';

const Header = () => {

  return (
    <div class='footer'>
        <div class='container'>
            <Link to="/">
                <img className="footer-logo" src={logoImage} alt="logo" />
            </Link>

            <div class='social-media'>
                <a href='https://www.facebook.com/newportbadmintonacademy'>
                    <i class="fa fa-facebook"></i>
                </a>
                <a href='https://www.instagram.com/newportbadmintonacademy'>
                    <i class="fa fa-instagram"></i>
                </a>

            </div>
        </div>

        <div class='container'>
        <nav className='index'>
        <ul>
          <li><Link to="/news">News</Link></li>
          <li><Link to="/meet-the-team">The Team</Link></li>
          <li><Link to="/find-us">Find Us</Link></li>
        </ul>
      </nav>

            <div class='signature'>
                <h3>Created by Pratyush Singh</h3>
            </div>
        </div>

    </div>
  );
};

export default Header;
