import React, { useEffect, useState } from 'react';
import { fetchEntries } from '../CMSProcessing';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './FindUs.css';

import VelodromeInfo from '../../../src/assets/velodrome-info.png'
import Sponsors from '../Sponsors/Sponsors';

const App = () => {
  const [data, setData] = useState({
    VelodromePic: '',
    ContactUs: ''
});

useEffect(() => {

    async function fetchData() {
        try {

            const entries = await fetchEntries();

            for (const entry of entries) {
                if (entry.fields.venuePhoto) {
                
                    setData({
                      VelodromePic: "https:" + entry.fields.venuePhoto.fields.file.url,
                      ContactUs: entry.fields.contactUs,
                  })
                }

            }



            

        } catch (error) {
            console.error('Error fetching entries:', error);
        }
    }

    fetchData();

}, []);

  return (
    <div class='all'>
      <Header />
      <div class='velodrome-container'>
        <img src={data.VelodromePic} class='velodrome-pic' alt='velodrome pic' />
        <div class='velodrome-content'>
          <img src={VelodromeInfo} class='velodrome-info' alt='velodrome info' />
        </div>
      </div>
      <div class='contact-container'>
      <div class='contact'>
          <h1><u>Contact Us</u></h1>
          <p>{data.ContactUs}</p>

      </div>
      </div>
      <Sponsors />
      <Footer />
    </div>
  );
};

export default App;