import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

import logoImage from '../../assets/nba-logo.jpg';

const Header = () => {

  const toggleNavbar = () => {
    const navbar = document.querySelector('.navbar');
    navbar.classList.toggle('active');
    const rightArrow = document.querySelector('.custom-next-arrow');
    const leftArrow = document.querySelector('.custom-prev-arrow');
    

    if(leftArrow){
      
      var rightArrowComputedStyle = window.getComputedStyle(rightArrow);
      var rightArrowDisplayStyle = rightArrowComputedStyle.getPropertyValue('display');
      if (rightArrowDisplayStyle !== 'none') {
        rightArrow.style.display = 'none';
        leftArrow.style.display = 'none';
      } else {
        rightArrow.style.display = 'block';
        leftArrow.style.display = 'block';
      }
    }

    

    
  };

  return (
    <header>
      <Link to="/">
        <img className="logo" src={logoImage} alt="logo" />
      </Link>
      {/* eslint-disable-next-line */}
      <a href='#' className="toggle-button" onClick={toggleNavbar}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </a>
      <nav className='navbar'>
        <ul>
          <li><Link to="/news">News</Link></li>
          <li><Link to="/meet-the-team">The Team</Link></li>
          <li><Link to="/find-us">Find Us</Link></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
