import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown'; // Import ReactMarkdown
import { fetchEntries } from '../CMSProcessing';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Sponsors from '../Sponsors/Sponsors';
import './MeetTheTeam.css';

const App = () => {
    const [data, setData] = useState({
        GroupPic: '',
        Coaches: [{
            Pic: '',
            Name: '',
            Text: '',
        }],
        Athletes: [{
            Pic: '',
            Name: '',
            Text: '',
        }],
    });

    useEffect(() => {

        async function fetchData() {
            try {
                

                const entries = await fetchEntries();
                
                var GroupPic = '';
                const Coaches = [];
                const Athletes = [];


                for (const entry of entries) {
                    
                    if (entry.fields.meetTheTeamPhoto) {
                        GroupPic = "https:" + entry.fields.meetTheTeamPhoto.fields.file.url;
                    }
                    if (entry.fields.name) {
                        Coaches.push({
                            Pic: "https:" + entry.fields.coachPic.fields.file.url,
                            Name: entry.fields.name,
                            Text: entry.fields.coachText,
                        })
                    }
                    if (entry.fields.playerName) {
                        Athletes.push({
                            Pic: "https:" + entry.fields.playerPhoto.fields.file.url,
                            Name: entry.fields.playerName,
                            Text: entry.fields.playerText,
                        })
                    }
                }



                setData({
                    GroupPic: GroupPic,
                    Coaches: Coaches,
                    Athletes: Athletes,
                })

            } catch (error) {
                console.error('Error fetching entries:', error);
            }
        }

        fetchData();

    }, []);

    

    return (
        <div class='all'>
            <Header />
            <div class='group-pic-container'>
                <img class='group-pic' src={data.GroupPic} alt='group pic'/>
                <div class="centered">
                    <h3>Meet the Team</h3>
                </div>
            </div>
            <div class='coach-container'>
                <h1><u>Coaches</u></h1>
                <div class='coach-cards'>
                    {data.Coaches.map((item, index) => (
                        <div class='coach-card' key={index}>
                            <img class='coach-pic' src={item.Pic} alt='coach pic'/>
                            <div class='coach-text'>
                                <h2>{item.Name}</h2>
                                <ReactMarkdown>{item.Text}</ReactMarkdown> {/* Render Markdown text */}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div class='athlete-container'>
                <h1><u>Athletes</u></h1>
                <div class='athlete-cards'>
                    {data.Athletes.map((item, index) => (
                        <div class='athlete-card' key={index}>
                            <img class='athlete-pic' src={item.Pic} alt='athlete pic'/>
                            <div class='athlete-text'>
                                <h2>{item.Name}</h2>
                                <ReactMarkdown>{item.Text}</ReactMarkdown> {/* Render Markdown text */}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Sponsors />
            <Footer />
        </div>
    );
}

export default App;
